/// Reset
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  font-stretch: normal;
  line-height: normal;
}

strong {
  font-weight: bold;

  em {
    font-family: "Roboto Bold Italic", Verdana, sans-serif;
  }
}

// Box sizing
*,
*::before,
*::after {
  box-sizing: border-box;
}

main {
  display: block; // For IE11 (The HTML5 main element is not supported by Internet Explorer)
}

// List
ul,
ol {
  list-style: none;
}

// Mark
mark {
  background: none;
}

// Separator
hr {
  width: 100%;
}

// Image
img {
  display: block;
  width: 100%;
  height: auto;
}

// Iframe
iframe {
  width: 100%;
  overflow: hidden;
  border: 0;
}

button {
  outline: none;
  background: none;
}
