.o-footer {
  // https://webkit.org/blog/7929/designing-websites-for-iphone-x/
  margin-bottom: env(safe-area-inset-bottom);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);

  @media #{$breakpoint-lg} {
    padding-bottom: 0;
  }

  border-top: 2px solid $color-primary;
  // background-image: linear-gradient(to bottom, $color-white, $color-gray);

  .o-footer-container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 25px 20px 20px;
  }

  .o-footer-navigation {
    .logo {
      width: 175px;

      @media #{$breakpoint-md} {
        width: 260px;
      }

      p {
        padding-top: 10px;
        color: $color-gray-darker;
        font-size: 14px;
      }
    }

    li {
      line-height: 1.5;

      &.current a {
        border-bottom: 2px solid $color-primary;
      }

      a {
        color: $color-primary;

        &:hover {
          border-bottom: 2px solid $color-primary;
        }
      }
    }
  }
}
