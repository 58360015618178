.o-section {
  margin-bottom: 30px;

  @media #{$breakpoint-md} {
    margin-bottom: 50px;
  }

  @media #{$breakpoint-lg} {
    max-width: 1010px;
    margin: 0 auto;
    margin-bottom: 80px;
    padding: 0;
  }
}
