// Element animated - add to animate.js (animatedElement)
.a-blockquote,
.m-card,
.m-figure-image {
  transition-timing-function: ease-in;
  animation-name: slide-in-down;
  visibility: hidden; // If Intersection Observer API is not available this will be set to visible
}

.js-animate {
  animation-name: slide-in-up;
  transform: translate3d(0, 0, 0);
  transition-timing-function: ease-in;
  animation-duration: 0.5s;
  visibility: visible;
}

@keyframes slide-in-down {
  from {
    transform: translate3d(0, -10%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-in-up {
  from {
    transform: translate3d(0, 10%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
