.o-slider {
  border-radius: $border-radius;
  .swiper-button-prev,
  .swiper-button-next {
    display: none;

    @media #{$breakpoint-md} {
      width: 50px;
      height: 50px;
    }

    border-radius: $border-radius;
    background-color: $color-primary;

    &.swiper-button-disabled {
      pointer-events: auto; // Overwite plugin default, to avoid slide-links to be executed
    }

    &:hover {
      background-color: $color-gray-light;

      span {
        border-top: 2px solid $color-primary;
        border-left: 2px solid $color-primary;
      }
    }

    &::after {
      content: ""; // Hide swiper.js default arrows
    }
  }

  @media #{$breakpoint-md} {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;

      span {
        display: block;
        width: 18px;
        height: 18px;
        margin: 15px auto;
        border-top: 3px solid $color-white;
        border-left: 3px solid $color-white;
      }
    }
  }

  .swiper-button-prev {
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      transform: rotate(-45deg);
    }
  }

  .swiper-button-next {
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    span {
      transform: rotate(135deg);
    }
  }

  .swiper-pagination-bullets {
    &.swiper-pagination-bullets-dynamic {
      bottom: 20px;
    }

    .swiper-pagination-bullet {
      @media #{$breakpoint-lg} {
        width: 10px;
        height: 10px;
      }

      opacity: 1;
      background-color: $color-white;

      &-active {
        background-color: $color-primary;
      }
    }
  }
}

// Slider Highlight
.o-slider {
  &.o-slider-highlight {
    .o-slider-slide-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 30px 30px 45px;

      @media #{$breakpoint-md} {
        padding: 30px 40px 45px;
      }

      @media #{$breakpoint-lg} {
        padding: 30px 50px 55px;
      }

      text-align: center;

      .heading {
        padding: 10px;
        border-radius: $border-radius;
        background-color: $color-black-transparent;
        color: $color-white;
        font-family: $font-bold;

        @media #{$breakpoint-md} {
          font-size: 20px;
        }
      }
    }
  }
}
