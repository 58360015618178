.o-article {
  blockquote,
  figure {
    margin-bottom: 30px;
  }

  p.paragraph {
    line-height: 1.5;
  }
}
