// Color Black
$color-black: #686c68;

// Color Black 50
$color-black-transparent: rgba(0, 0, 0, 0.7);

// Color White
$color-white: #fff;

// Color Primary
$color-primary: #d8213b;

// Color Secondary
$color-secondary: #d8213b;

// Color Gray
$color-gray: #d7dcdf;

// Color Gray lighter
$color-gray-lighter: #f1f4f6;

// Color Gray light
$color-gray-light: #e3e8ed;

// Color Gray dark
$color-gray-dark: #a9b2ba;

// Color Gray darker
$color-gray-darker: #666f77;
