.m-list {
  margin: 0 0 20px 20px;

  li {
    @media #{$breakpoint-md} {
      font-size: 18px;
    }

    line-height: 1.8;
  }

  &--unordered {
    list-style-type: disc;
  }

  &--ordered {
    list-style-type: decimal;
  }
}
