.o-header {
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);

  .o-header-container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 25px 20px 40px;
  }

  .o-header-navigation {
    .logo {
      width: 220px;

      @media #{$breakpoint-sm} {
        width: 260px;
      }
    }

    .navigation {
      visibility: hidden;
      height: 0;

      // Small screen navigation
      &.visible {
        visibility: visible;
        position: absolute;
        z-index: map-get($z-index, navigation);
        top: 0;
        right: 0;
        width: 250px;
        height: auto;
        padding: 88px 0 20px;
        border-bottom-left-radius: $border-radius;
        background-color: $color-primary;
        font-family: $font-bold;

        a {
          margin-left: 20px;
          color: $color-white;

          &:hover {
            border-bottom: 2px solid $color-white;
          }
        }

        li {
          // First level
          line-height: 1.8;

          &.current {
            background-color: $color-white;

            a {
              color: $color-primary;

              &:hover {
                border-bottom: 2px solid $color-primary;
              }
            }
          }

          ul {
            margin-bottom: 10px;
            font-family: $font-regular;

            li {
              // Second level
              padding-left: 20px;

              &.current {
                background-color: $color-white;

                a {
                  border-bottom: 2px solid $color-primary;
                  color: $color-primary;
                }
              }
            }
          }
        }
      }

      @media #{$breakpoint-lg} {
        visibility: visible;
        margin-top: -1px; // Align "current" a border-bottom to logo line
        font-family: $font-medium;

        a {
          color: $color-primary;

          &:hover {
            border-bottom: 2px solid $color-primary;
          }
        }

        li {
          display: inline;
          margin-right: 10px;
          line-height: 1.5;

          &.current > a {
            border-bottom: 2px solid $color-primary;
          }

          &.current ul {
            visibility: visible;
          }

          ul {
            display: flex;
            visibility: hidden;
            position: absolute;

            &.parent { // Subnaviagtion list
              visibility: visible;
            }

            li {
              &.current a {
                color: $color-primary;
              }
            }
          }
        }
      }
    }

    .navicon {
      position: absolute;
      z-index: map-get($z-index, navicon);
      top: 25px;
      right: 10px;
      padding: 20px;

      @media #{$breakpoint-lg} {
        visibility: hidden;
      }

      span {
        transition: transform 200ms ease-in-out, background-color 100ms 100ms linear;

        &,
        &::before,
        &::after {
          display: block;
          position: relative;
          width: 25px;
          height: 3px;
          border-radius: $border-radius;
          background-color: $color-black;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          transition: transform 200ms ease-in-out;
        }

        &::before {
          transform: rotate(0) translate(0, -9px);
          transform-origin: 50% 50%;
        }

        &::after {
          transform: rotate(0) translate(0, 9px);
          transform-origin: 100% 50%;
        }

        &.active {
          transform: rotate(-45deg);
          transition-delay: 0ms, 0ms;
          background-color: $color-white;

          &::before,
          &::after {
            background-color: $color-white;
          }

          &::before {
            transform: rotate(90deg) translate(0, 0);
          }

          &::after {
            transform: rotate(0) translate(0, 0);
          }
        }
      }
    }
  }
}
