.a-blockquote {
  padding: 20px;
  color: $color-gray-darker;
  font-family: $font-regular-italic;

  p {
    margin-bottom: 10px;

    @media #{$breakpoint-md} {
      margin-bottom: 20px;
    }

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }

  span {
    position: absolute;

    @media #{$breakpoint-md} {
      right: 20px;
    }

    color: $color-black;

    &::before {
      content: "– ";
    }
  }
}
