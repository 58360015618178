// Max width
$max-width: 1200px;

// Screen XS size (max-width)
$screen-xs-size: 375px; // Target max-width for small screens

// Screen SM size (min-width)
$screen-sm-size: 375px;

// Screen MD size (min-width)
$screen-md-size: 500px; // Remember: Hardcoded in navigation.js

// Screen LG size (min-width)
$screen-lg-size: 1025px;

// Breakpoint
$breakpoint-xs: "(max-width: #{$screen-xs-size})";
$breakpoint-sm: "(min-width: #{$screen-sm-size})";
$breakpoint-md: "(min-width: #{$screen-md-size})";
$breakpoint-lg: "(min-width: #{$screen-lg-size})";

// Border
$border-radius: 4px;

// Z-index map
$z-index: (
  navicon: 10000,
  navigation: 1000,
  back-to-top: 3000,
  back: -100
);

// Global grid gap
$grid-gap: 20px;
