.m-back-to-top {
  // https://www.nngroup.com/articles/back-to-top/
  visibility: hidden;
  position: fixed;
  z-index: map-get($z-index, back-to-top);
  right: 20px;
  bottom: -50px;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease-in-out;
  border-radius: $border-radius;
  background-color: $color-primary;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media #{$breakpoint-md} {
    width: 50px;
    height: 50px;
  }

  &--visible {
    visibility: visible;
    bottom: 20px;
    margin-bottom: env(safe-area-inset-bottom);

    @media #{$breakpoint-md} {
      bottom: 140px;
    }

    @media #{$breakpoint-lg} {
      bottom: 80px;
    }
  }

  // Solving Sticky Hover States with @media (hover: hover)
  // https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
  @media (hover: hover) {
    &:hover {
      background-color: $color-gray-dark;
    }
  }

  // Arrow up
  span {
    display: block;
    width: 18px;
    height: 18px;
    margin: 17px auto;

    @media #{$breakpoint-md} {
      margin: 20px auto;
    }

    transform: rotate(45deg);
    border-top: 2px solid $color-white;
    border-left: 2px solid $color-white;
  }
}
