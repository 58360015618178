// Font regular
$font-regular: "Roboto",
Verdana,
sans-serif;

// Font regular italic
$font-regular-italic: "Roboto Italic";

// Font medium
$font-medium: "Roboto Medium";

// Font bold
$font-bold: "Roboto Bold";

@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src:
    local("Roboto"),
    local("Roboto-Regular"),
    url("../font/roboto-v20-latin-regular.woff2") format("woff2"),
    url("../font/roboto-v20-latin-regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Italic";
  font-weight: 400;
  src:
    local("Roboto Italic"),
    local("Roboto-Italic"),
    url("../font/roboto-v20-latin-italic.woff2") format("woff2"),
    url("../font/roboto-v20-latin-italic.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Medium";
  font-weight: 500;
  src:
    local("Roboto Medium"),
    local("Roboto-Medium"),
    url("../font/roboto-v20-latin-500.woff2") format("woff2"),
    url("../font/roboto-v20-latin-500.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src:
    local("Roboto Bold"),
    local("Roboto-Bold"),
    url("../font/roboto-v20-latin-700.woff2") format("woff2"),
    url("../font/roboto-v20-latin-700.woff") format("woff");
  font-display: swap;
}

// Headlines which visually separate and introduce content
.h1 {
  margin-bottom: 15px;
  font-family: $font-bold;
  font-size: 20px;
  line-height: 1.4;

  @media #{$breakpoint-md} {
    margin-bottom: 20px;
    font-size: 30px;
  }
}

.h2 {
  margin-bottom: 15px;
  font-family: $font-bold;
  font-size: 18px;

  @media #{$breakpoint-md} {
    font-size: 20px;
  }
}

.h3 {
  margin-bottom: 8px;
  font-family: $font-bold;
  font-size: 16px;

  @media #{$breakpoint-md} {
    font-size: 18px;
  }
}

.h1,
.h2,
.h3 {
  span.color--primary {
    color: $color-primary;
  }

  span.color--secondary {
    color: $color-secondary;
  }
}

// Generic paragraph style for medium to long blocks of text and excerpt
.paragraph {
  margin-bottom: 20px;
  line-height: 1.38;
  word-break: break-word;

  @media #{$breakpoint-md} {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 1.44;
  }

  a {
    color: $color-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  &--excerpt {
    font-family: $font-medium;
  }
}

blockquote {
  font-size: 24px;

  span {
    font-size: 16px;
  }

  @media #{$breakpoint-md} {
    font-size: 30px;

    span {
      font-size: 18px;
    }
  }
}
