.m-figure-image {
  img {
    border-radius: $border-radius;
  }

  figcaption {
    margin-left: 5px;
    padding: 10px 0;
    color: $color-gray-darker;

    p {
      color: $color-gray-dark;
    }
  }
}
