.m-iframe {
  .m-iframe-wrapper {
    overflow: hidden;
    border: 1px solid $color-gray-light;
    border-radius: $border-radius;
  }

  .m-iframe-map {
    height: 200px;

    @media #{$breakpoint-md} {
      height: 300px;
    }

    @media #{$breakpoint-lg} {
      height: 500px;
    }

    filter: grayscale(0.8);
  }
}
