/// Main (only to control the HTML5 main element)
.main {
  max-width: $max-width;
  margin: 0 auto;
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  color: $color-black;

  // Use wrapper to get a full-width background-color
  .main-wrapper {
    position: relative;
    left: 0;
    width: 100%;

    @media #{$breakpoint-lg} {
      // Hack the child to fit the width of browser viewport
      // https://stackoverflow.com/questions/5581034/is-there-are-way-to-make-a-child-divs-width-wider-than-the-parent-div-using-css/61827217#61827217
      left: calc(-50vw + 50%);
      width: 100vw;
    }

    padding-bottom: 30px;

    @media #{$breakpoint-md} {
      padding-bottom: 50px;
    }

    &--gray-lighter {
      background-color: $color-gray-lighter;
    }

    &--gray-darker {
      background-color: $color-gray-darker;
    }
  }

  .main-container {
    max-width: $max-width;
    margin: 0 auto;
    padding: 25px 20px 40px;

    @media #{$breakpoint-md} {
      padding: 25px 20px 5px;
    }
  }
}
