// Invisible content just for screen reader users
// <http://webaim.org/techniques/css/invisiblecontent/>
.screenreader {
  position: absolute;
  top: auto;
  left: -1000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// Hidden XS
.hidden-xs {
  @media #{$breakpoint-xs} {
    display: none;
  }
}

// Hidden SM
.hidden-sm {
  @media #{$breakpoint-sm} {
    display: none;
  }
}

// Show the box - Use this for debugging only
// https://css-tricks.com/the-css-box-model/#article-header-id-5
// * {
//   border: 1px solid darkgray !important;
// }

// Show current breakpoint - Use this for debugging only
// body {
//   &::after {
//     position: fixed;
//     z-index: 9999;
//     top: 0;
//     right: 0;
//     padding: 5px 10px;
//     opacity: 0.8;
//     background: $color-gray-darker;
//     color: white;
//     font-size: 14px;
//
//     @media #{$breakpoint-sm} {
//       content: "Breakpoint S";
//     }
//
//     @media #{$breakpoint-md} {
//       content: "Breakpoint M";
//     }
//
//     @media #{$breakpoint-lg} {
//       content: "Breakpoint L";
//     }
//   }
// }
