.grid {
  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: 1fr;
  overflow: visible;

  &--max-2 {
    @media #{$breakpoint-md} {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--max-3 {
    @media #{$breakpoint-md} {
      grid-template-columns: 1fr 1fr;
    }

    @media #{$breakpoint-lg} {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--max-4 {
    @media #{$breakpoint-md} {
      grid-template-columns: 1fr 1fr;
    }

    @media #{$breakpoint-lg} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &--navigation-header {
    grid-gap: 0; // Avoid jumping content

    @media #{$breakpoint-md} {
      grid-gap: 20px;
      grid-template-columns: 1fr 3fr;
    }
  }

  &--navigation-footer {
    grid-template-columns: 1fr 1fr;

    @media #{$breakpoint-lg} {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &--article {
    @media #{$breakpoint-lg} {
      grid-template-columns: 3fr 1fr;
    }
  }

  &--article-wide {
    aside {
      display: grid;

      @media #{$breakpoint-md} {
        grid-template-columns: 1fr 1fr;
      }

      @media #{$breakpoint-lg} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}
