/// Basic styling goes here, these are general defintions for the project
html {
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; // Prevent font scaling in landscape while allowing user zoom
  text-rendering: optimizeLegibility;
  text-shadow: none;
  scroll-behavior: smooth;
}

// Respect preference for reduced motion effect
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  min-height: 100vh;
  overflow-x: hidden; // For main-wrapper outbreak only
  font-family: $font-regular;
}

a {
  text-decoration: none;
}

// Text
mark {
  padding: 0 5px;
  background: $color-secondary;
  color: $color-white;
  border-radius: $border-radius;
}

b,
strong {
  font-family: $font-bold;
}

i,
em {
  font-family: $font-regular-italic;
}

small {
  font-size: 12px;
}

// Miscellaneous
::selection {
  background-color: $color-primary;
  color: $color-white;
}
