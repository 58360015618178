.m-card {
  margin: 0 5px 20px;

  @media #{$breakpoint-md} {
    margin-bottom: 50px;
  }

  transition: all 250ms ease;
  border-radius: $border-radius;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);

  img {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:hover {
    @media #{$breakpoint-md} {
      transform: translateY(-5px);
    }

    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  }

  .m-card-wrapper {
    height: 100%;
  }

  .m-card-content {
    padding: 20px;

    span.h3 {
      color: $color-black;
      line-height: 1.3;
      hyphens: auto;
    }

    .excerpt {
      margin-top: 10px;
      color: $color-black;
    }
  }
}
